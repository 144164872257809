import {NgModule} from '@angular/core';
import * as shared from '@shared/index';

@NgModule({
    imports: [
        ...shared.imports,
    ],
    declarations: [
        ...shared.directives,
        ...shared.pipes,
        ...shared.components,
        ...shared.dialoguesComponents,
    ],
    exports: [
        ...shared.imports,
        ...shared.directives,
        ...shared.pipes,
        ...shared.components,
    ],
})
export class SharedModule {
}
