import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {calculateDateInRange} from '@shared/functions/dates-utils';

@Directive({
  selector: '[appDateRangeCheck]'
})
export class DateRangeCheckDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {
  }

  @Input() set appDateRangeCheck(range: `${number}-${number}`) {
    calculateDateInRange(range) ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }

}
