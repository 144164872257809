<footer>
    <div class="container">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-5">
                <a [routerLink]="['/' | localize]">
                    <img alt="OTO English Courses" class="img__pixelated" height="70px"
                         lazyLoad="./assets/images/header/OTOCourseslogo.svg"
                         width="70px">
                </a>
                <h2 class="oto__logo">OTO Courses</h2>
                <p class="list__content">
                    {{'pages.footer.about_us' | translate}}
                </p>
            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-2">
                <div class="links">
                    <p class="list__title">{{'pages.footer.other_links' | translate}}</p>
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a class="nav-link" href="https://otocourses.off2class.com/login" rel="noopener"
                               target="_blank">{{'pages.footer.off2class' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/contact-us' | localize]"
                               class="nav-link">{{'pages.footer.enterprise' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://www.facebook.com/otokidsenglish" rel="noopener"
                               target="_blank">{{'pages.footer.kids' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="https://dardshenglish.com" rel="noopener" target="_blank">
                                {{'pages.footer.dardsh_english' | translate}}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a *appShowAuthed="false" class="nav-link"
                               href="https://docs.google.com/forms/d/e/1FAIpQLScsgoqugr9ldpSG5dzZHYFDbeDpnFcukkM0WXwlOQeOTr30ow/viewform?fbclid=IwAR0quozrwozcbJr27J-11x8Zq_vB2aN7sqgVyUbMXpHwwEw7J7g-EWlYDFA"
                               rel="noreferrer"
                               target="_blank">{{'pages.footer.teacher_application' | translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-2">
                <div class="terms">
                    <p class="list__title">{{'pages.footer.terms_and_policy' | translate}}</p>
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <a [routerLink]="['/terms-conditions' | localize]" class="nav-link">
                                {{'pages.footer.terms_and_conditions' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/cancellation-and-refund-policy' | localize]" class="nav-link">
                                {{'pages.footer.cancellation_and_refund_policy' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/privacy-policy' | localize]"
                               class="nav-link">{{'pages.footer.privacy_policy' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a [routerLink]="['/contact-us' | localize]"
                               class="nav-link">{{'pages.footer.contact_us' | translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="contact-us">
                    <p class="list__title">{{'pages.footer.address' | translate}}</p>
                    <a
                        class="our-font-color"
                        href="https://www.google.com/maps/place/OTO+Courses/@29.973846,31.2790257,17z/data=!3m1!4b1!4m5!3m4!1s0x145839da88de3035:0xff88277ab9cd5df5!8m2!3d29.9738414!4d31.2812144"
                        rel="noopener"
                        target="_blank">
                        {{'pages.footer.full_address' | translate}}</a>
                    <ul class="nav flex-row social-media">
                        <li class="nav-item">
                            <a aria-label="oto english facebook page" class="nav-link"
                               href="https://www.facebook.com/otoenglish"
                               rel="noopener"
                               target="_blank">
                                <svg height="20px" id="Capa_1" style="enable-background:new 0 0 155.139 155.139;"
                                     version="1.1" viewBox="0 0 155.139 155.139" width="20px"
                                     x="0px"
                                     xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                                     y="0px">
                      <g>
                        <path class="iconSvg" d="M89.584,155.139V84.378h23.742l3.562-27.585H89.584V39.184
                          c0-7.984,2.208-13.425,13.67-13.425l14.595-0.006V1.08C115.325,0.752,106.661,0,96.577,0C75.52,0,61.104,12.853,61.104,36.452
                          v20.341H37.29v27.585h23.814v70.761H89.584z" id="f_1_" style=""/>
                      </g>
                  </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a aria-label="oto english instagram page" class="nav-link"
                               href="https://www.instagram.com/otocourses/"
                               rel="noopener" target="_blank">
                                <svg class="iconSvg" id="Capa_1" style="enable-background:new 0 0 512 512;"
                                     version="1.1" viewBox="0 0 512 512" x="0px"
                                     xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
                  <g>
                  <g>
                  <path d="M363.273,0H148.728C66.719,0,0,66.719,0,148.728v214.544C0,445.281,66.719,512,148.728,512h214.544
                  C445.281,512,512,445.281,512,363.273V148.728C512,66.719,445.281,0,363.273,0z M472,363.272C472,423.225,423.225,472,363.273,472
                  H148.728C88.775,472,40,423.225,40,363.273V148.728C40,88.775,88.775,40,148.728,40h214.544C423.225,40,472,88.775,472,148.728
                  V363.272z"/>
                  </g>
                  </g>
                                    <g>
                  <g>
                  <path d="M256,118c-76.094,0-138,61.906-138,138s61.906,138,138,138s138-61.906,138-138S332.094,118,256,118z M256,354
                  c-54.037,0-98-43.963-98-98s43.963-98,98-98s98,43.963,98,98S310.037,354,256,354z"/>
                  </g>
                  </g>
                                    <g>
                  <g>
                  <circle cx="396" cy="116" r="20"/>
                  </g>
                  </g>
                  </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a aria-label="oto english youtube page"
                               class="nav-link"
                               href="https://www.youtube.com/channel/UCaw-s_spN4V05QOKhi21Jeg" rel="noopener"
                               target="_blank">
                                <svg height="20px" id="Layer_1" style="enable-background:new 0 0 461.001 461.001;"
                                     version="1.1" viewBox="0 0 461.001 461.001" width="20px"
                                     x="0px"
                                     xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                                     y="0px">
                <path class="iconSvg" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
                  c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
                  C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
                  c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"/>
                </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a aria-label="oto english email address"
                               class="nav-link"
                               href="mailto:hello@otocourses.com" target="_blank"
                               title="hello@otocourses.com"><i class="fas fa-envelope"></i>
                                <svg class="iconSvg" id="Capa_1" style="enable-background:new 0 0 433.664 433.664;"
                                     version="1.1" viewBox="0 0 433.664 433.664" x="0px"
                                     xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                                     y="0px">
                  <g>
                  <g>
                  <path d="M229.376,271.616c-4.096,2.56-8.704,3.584-12.8,3.584s-8.704-1.024-12.8-3.584L0,147.2v165.376c0,35.328,28.672,64,64,64
                  h305.664c35.328,0,64-28.672,64-64V147.2L229.376,271.616z"/>
                  </g>
                  </g>
                                    <g>
                  <g>
                  <path d="M369.664,57.088H64c-30.208,0-55.808,21.504-61.952,50.176l215.04,131.072l214.528-131.072
                  C425.472,78.592,399.872,57.088,369.664,57.088z"/>
                  </g>
                  </g>
                  </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a aria-label="oto english linkedin page" class="nav-link"
                               href="https://www.linkedin.com/company/oto-courses/"
                               rel="noopener" target="_blank">
                                <svg class="iconSvg" height="510px" id="Capa_1"
                                     style="enable-background:new 0 0 510 510;" version="1.1"
                                     viewBox="0 0 510 510"
                                     width="510px" x="0px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                                     y="0px">
                <g>
                <g id="post-linkedin">
                <path d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z
                M153,433.5H76.5V204H153V433.5z M114.75,160.65c-25.5,0-45.9-20.4-45.9-45.9s20.4-45.9,45.9-45.9s45.9,20.4,45.9,45.9
                S140.25,160.65,114.75,160.65z M433.5,433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25,17.851-38.25,38.25V433.5H204
                V204h76.5v30.6c12.75-20.4,40.8-35.7,63.75-35.7c48.45,0,89.25,40.8,89.25,89.25V433.5z"/>
                </g>
                </g>
              </svg>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                aria-label="oto english phone number" class="nav-link" href="tel:+201222200609"
                                target="_blank"
                                title="+201222200609">
                                <svg class="iconSvg" id="Capa_1" style="enable-background:new 0 0 513.64 513.64;"
                                     version="1.1" viewBox="0 0 513.64 513.64" x="0px"
                                     xml:space="preserve" xmlns="http://www.w3.org/2000/svg" y="0px">
                      <g>
                      <g>
                      <path d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72
                      c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68
                      c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44
                      l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"/>
                      </g>
                      </g>
                      </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row align-items-end">
            <div class="col-12 col-sm-12 col-md-6 d-flex align-items-center mb-1">
                <div class="payments w-100">
                    <ul class="nav nav__container flex-row">
                        <li class="nav__item">
                              <span class="nav__link">
                                 <img alt="Visa"
                                      class="multi__gatway"
                                      src="/assets/images/payments/PaymentMethods.png">
                              </span>
                        </li>
                        <!--                    -->
                        <!--                        <li class="nav__item">-->
                        <!--                              <span class="nav__link">-->
                        <!--                                 <img alt="Visa"-->
                        <!--                                      class="regular__img"-->
                        <!--                                      lazyLoad="/assets/images/payments/thumbnail_unnamed%20(1).png">-->
                        <!--                              </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--              <span class="nav__link">-->
                        <!--                 <img alt="mastercard"-->
                        <!--                      class="regular__img big__img"-->
                        <!--                      lazyLoad="/assets/images/payments/mastercard-logo-11530966761nhfzlt6qu8.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--                                 <span class="nav__link">-->
                        <!--                 <img alt="fawry"-->
                        <!--                      class="regular__img"-->
                        <!--                      lazyLoad="/assets/images/payments/fawry.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--              <span class="nav__link">-->
                        <!--                 <img alt="meeza"-->
                        <!--                      class="regular__img big__img"-->
                        <!--                      lazyLoad="/assets/images/payments/thumbnail_a9e1ed88001167.5dc9778d3ed42.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--              <span class="nav__link">-->
                        <!--                 <img alt="orange"-->
                        <!--                      class="regular__img"-->
                        <!--                      lazyLoad="/assets/images/payments/thumbnail_Orange_Egypt-Logo.wine.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--              <span class="nav__link">-->
                        <!--                 <img alt="etisalate"-->
                        <!--                      class="regular__img"-->
                        <!--                      lazyLoad="/assets/images/payments/thumbnail_Etisalat_Misr-Logo.wine.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--              <span class="nav__link">-->
                        <!--                 <img alt="vodafone cash"-->
                        <!--                      class="regular__img"-->
                        <!--                      lazyLoad="/assets/images/payments/thumbnail_5e12f63ba26feb85c775223829721df1.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                        <!--                        <li class="nav__item">-->
                        <!--              <span class="nav__link">-->
                        <!--                 <img alt="small wallet"-->
                        <!--                      class="regular__img"-->
                        <!--                      lazyLoad="/assets/images/payments/thumbnail_unnamed.png">-->
                        <!--                 </span>-->
                        <!--                        </li>-->
                    </ul>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6  d-flex align-items-center">
                <div class="row d-flex align-items-center justify-content-start mt-5">
                    <div class="col-12">
                        <div>
                            <p class="powered__nav__title"> {{'pages.footer.powered_by' | translate}}</p>
                        </div>
                    </div>
                    <div class="col-12">
                        <ul class="powered__nav">
                            <!--                            <li>-->
                            <!--                                <img alt="amazon hidden" lazyLoad="../../../../assets/images/NT3LM Logo.png"/>-->
                            <!--                            </li>-->
                            <li>
                                <img alt="amazon hidden" lazyLoad="../../../../assets/images/amazon.svg"/>
                            </li>
                            <li class="big__width">
                                <img alt="microsoft" lazyLoad="../../../../assets/images/microsoft.png"/>
                            </li>
                            <li>
                                <img alt="nahdt masr" lazyLoad="../../../../assets/images/footer/nhdet-masr.png"/>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-sm-12 col-md-8">
                <div class="copyright">
                    <p class="list__content">
                        {{'pages.footer.copyright' | translate}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
